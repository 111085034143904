import axios from 'axios';
import useSWRImmutable from 'swr/immutable';

const useWalkthrough = ({ walkthrough }: { walkthrough: 'desktop' | 'mobile' | 'tablet' }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<Blob | null>(
    () => {
      if (!walkthrough) return null;

      return `walthrough-data-${walkthrough}`;
    },
    async () => {
      if (!walkthrough) return null;

      const response = await axios.get(
        `${import.meta.env.VITE_UNHURD_CDN}/files/${walkthrough}-web-builder-walkthrough.json`
      );

      return await response.data;
    }
  );

  return {
    walkthrough: data,
    walkthroughIsLoading: isLoading,
    walkthroughError: error,
    refetchWalkthrough: mutate,
  };
};

export default useWalkthrough;
