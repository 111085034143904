import { useEffect, useState } from 'react';

import { JsonType } from 'posthog-js';

import { FeatureFlagNameFormatter } from '@/constants/FeatureFlagEvents';
import { DefaultSubscriptionFeatureFlagPayload } from '@/constants/ProductList';
import { SubscriptionDataModel } from '@/models/Payments';
import useFeatureFlags from '@/services/FeatureFlag';

import useAccountContext from '../context/useAccountContext';

const usePricing = () => {
  const { accountId } = useAccountContext();
  const { getDistinctId, getFeatureFlagPayload, isPricingTestGroup } = useFeatureFlags();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionDataModel[] | JsonType>();

  useEffect(() => {
    if (getDistinctId() !== accountId) return;

    const data: SubscriptionDataModel[] | JsonType = getFeatureFlagPayload(
      FeatureFlagNameFormatter('isVariablePricingEnabled')
    );

    if (isPricingTestGroup) {
      setSubscriptionData(data);
    } else {
      setSubscriptionData(DefaultSubscriptionFeatureFlagPayload);
    }

    if (!data) {
      setSubscriptionData(DefaultSubscriptionFeatureFlagPayload);
      return setIsDataLoading(false);
    }

    setIsDataLoading(false);
  }, [accountId, getDistinctId, getFeatureFlagPayload, isPricingTestGroup]);

  return {
    subscriptionData: subscriptionData,
    isDataLoading: isDataLoading,
  };
};

export default usePricing;
